.floating-help-button {
  position: fixed;
  top: 100px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #AF1E15;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, background-color 0.2s ease;
  z-index: 1000;
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight on mobile */
}

.help-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-icon.primary,
.help-icon.backup {
  min-width: 24px;
  min-height: 24px;
}

.floating-help-button:hover {
  transform: scale(1.1);
  background-color: #8f1811;
}

.floating-help-button:active {
  transform: scale(0.95); /* Add feedback for mobile taps */
}

/* Mobile Help Button */
.mobile-help-button {
  display: block;
  width: 300px; /* Match the Learn More button width */
  margin: 10px auto;
  padding: 15px 30px;
  background-color: #AF1E15;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.2em;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  -webkit-tap-highlight-color: transparent;
}

.mobile-help-button:hover,
.mobile-help-button:active {
  background-color: #8f1811;
}

.help-bar {
  position: fixed;
  bottom: -100px;
  left: 20px;
  max-width: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 999;
  border: 1px solid #eaeaea;
  opacity: 0;
}

.help-bar.visible {
  bottom: 100px;
  opacity: 1;
}

.help-bar-content {
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: 'Noto Sans', sans-serif;
}

.help-bar-content span {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.help-bar-button {
  background-color: #AF1E15;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Noto Sans', sans-serif;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  transition: background-color 0.2s ease;
  -webkit-tap-highlight-color: transparent;
}

.help-bar-button:hover {
  background-color: #8f1811;
}

.help-bar-close {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  margin-left: auto;
  -webkit-tap-highlight-color: transparent;
}

.help-bar-close:hover {
  color: #333;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.video-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
}

.video-modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  color: #333;
  cursor: pointer;
  padding: 8px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 2001;
}

.video-modal-close:hover {
  transform: scale(1.1);
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .floating-help-button {
    top: auto;
    bottom: 20px;
    right: 20px;
    width: 50px; /* Larger on mobile */
    height: 50px; /* Larger on mobile */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .video-modal-content {
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }

  .video-modal-close {
    top: 10px;
    right: 10px;
    font-size: 28px;
    padding: 12px;
  }

  iframe {
    flex: 1;
    width: 100%;
    height: 100% !important;
  }

  .help-icon {
    font-size: 28px;
    width: 28px;
    height: 28px;
  }

  .help-icon.primary,
  .help-icon.backup {
    min-width: 28px;
    min-height: 28px;
  }
} 