/* © 2024 Learning for Well-Being Institute. All rights reserved.
   Policy Decision Tree
   policydecisions.org */

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  text-align: center;
}

.loading-text {
  font-family: 'Noto Sans', sans-serif;
  font-size: 24px;
  color: var(--highlight-color);
  font-weight: bold;
  margin-bottom: 20px;
}

.progress-bar-container {
  width: 300px;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--highlight-color);
  transition: width 0.3s ease-in-out;
}
