/* © 2024 Learning for Well-Being Institute. All rights reserved.
   Policy Decision Tree
   policydecisions.org */

.selection-ui {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    position: relative; /* Create new stacking context */
}

/* Prevent horizontal scrolling on the body */
body {
    overflow-x: hidden;
    width: 100%;
}

.selection-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns by default */
    gap: 30px;
}

.main-category {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    padding: 30px;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
}

.main-category:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
}

.main-category h2 {
    color: var(--highlight-color);
    font-family: 'GrotaSansRd-Black', sans-serif;
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 28px;
    border-bottom: 2px solid var(--highlight-color);
    padding-bottom: 10px;
}

.subcategory-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.category {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 12px;
    padding: 20px;
    transition: all 0.3s ease;
}

.category:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.category h3 {
    color: var(--text-black);
    font-size: 20px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 15px;
}

.category select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    background-color: white;
    margin-bottom: 15px;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    background-size: 14px auto;
}

.selected-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.selected-option {
    background-color: var(--highlight-color);
    color: white;
    padding: 8px 14px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    transition: all 0.3s ease;
}

.selected-option:hover {
    background-color: #8B1811;
}

.selected-option button {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-button {
    background-color: var(--highlight-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-family: 'GrotaSansRd-Black', sans-serif;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #8B1811;
}

.reset-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: var(--highlight-color);
    font-size: 22px;
    cursor: pointer;
}

.reset-button:hover {
    color: var(--highlight-color);
    transform: rotate(90deg);
    transition: transform 0.3s ease; /* Added ease for smooth rotation */
}

.reset-button:hover .reset-text {
    display: block;
}

.reset-text {
    display: none;
    position: absolute;
    top: -25px;
    right: 0;
    background-color: white;
    color: var(--highlight-color);
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(720deg); } /* 2 full rotation */
}

.rotate {
    animation: rotate 0.5s ease-in-out; /* Adjusted duration for smoother rotation */
}

@media (max-width: 992px) {
    .selection-grid {
        grid-template-columns: 1fr;
    }
    
    .selection-ui {
        max-width: 95%; /* Increased from 80% */
        padding: 10px; /* Reduced padding */
    }

    .main-category {
        padding: 20px; /* Reduced padding */
    }
}

@media (max-width: 768px) {
    .selection-ui {
        max-width: 98%;
        padding: 5px;
        margin: 0 auto;
    }

    .subcategory-grid {
        grid-template-columns: 1fr;
    }

    .main-category {
        padding: 15px; /* Further reduced padding */
        border-radius: 12px; /* Slightly reduced border radius */
    }

    .category {
        padding: 15px; /* Reduced padding */
    }

    /* Adjust font sizes for better mobile display */
    .main-category h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .category h3 {
        font-size: 18px;
        margin-bottom: 12px;
    }
}

/* Even smaller screens */
@media (max-width: 480px) {
    .selection-ui {
        max-width: 99%;
        padding: 3px;
    }

    .main-category {
        padding: 12px;
        border-radius: 10px;
    }
}

.category-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.category-header h3 {
    margin: 0;
}

.info-icon-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
}

/* Create a large hover bridge */
.info-icon-container::before {
    content: '';
    position: absolute;
    top: -20px;
    left: -50px;
    right: -50px;
    bottom: -20px;
    background: transparent;
    z-index: 1;
}

/* Create a bridge to the tooltip */
.info-icon-container::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: min(600px, 90vw);
    height: 60px;
    transform: translateX(-50%);
    background: transparent;
    pointer-events: none;
}

.info-icon {
    color: var(--highlight-color);
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
    z-index: 2;
    position: relative;
}

.info-icon:hover {
    color: #8B1811;
}

.criteria-box {
    position: absolute;
    top: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%) scale(0.95);
    background-color: black;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 15px;
    width: min(350px, 85vw);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: 
        opacity 0.4s ease,
        visibility 0s linear 1s,
        transform 0.3s ease;
    pointer-events: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent scrolling */
}

/* Direction-aware positioning */
.info-icon-container.right-aligned .criteria-box {
    left: auto;
    right: 0;
    transform: translateX(0) scale(0.95);
}

.info-icon-container.right-aligned:hover .criteria-box {
    transform: translateX(0) scale(1);
}

.info-icon-container.left-aligned .criteria-box {
    left: 0;
    right: auto;
    transform: translateX(0) scale(0.95);
}

.info-icon-container.left-aligned:hover .criteria-box {
    transform: translateX(0) scale(1);
}

/* Desktop hover behavior with delay and persistence */
@media (hover: hover) and (pointer: fine) {
    .info-icon-container:hover .criteria-box {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transition-delay: 0.2s;
        transition-duration: 0.3s;
    }

    .criteria-box:hover {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transition-delay: 0s;
        transition: 
            opacity 0.4s ease,
            visibility 0s linear 1s,
            transform 0.3s ease;
    }
}

/* Touch device and mobile adjustments */
@media (hover: none), (pointer: coarse) {
    .criteria-box {
        position: fixed;
        left: 0 !important;
        right: 0 !important;
        top: 50% !important;
        transform: translateY(-50%) scale(0.95) !important;
        width: 90% !important;
        max-width: 400px;
        margin: 0 auto;
        padding: 25px;
        font-size: 16px;
        background-color: #fff;
        color: #333;
        border-radius: 12px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        z-index: 9998;
    }

    /* Create a backdrop for touch devices */
    .mobile-backdrop {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9997;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .mobile-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        display: none;
    }

    .mobile-modal.active {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile-modal-content {
        position: relative;
        width: 90%;
        max-width: 400px;
        background: white;
        padding: 25px;
        border-radius: 12px;
        z-index: 9999;
        color: #333;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        margin: 20px;
    }

    .mobile-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        color: #666;
        font-size: 24px;
        cursor: pointer;
        z-index: 10000;
        padding: 8px;
    }

    .mobile-close:hover {
        color: #333;
    }

    /* Style adjustments for touch device content */
    .criteria-box a {
        color: var(--highlight-color);
        font-weight: 500;
        padding: 8px 0; /* Increased touch target */
        margin: 4px 0;
        display: inline-block;
    }

    .criteria-box p {
        color: #333;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 15px;
    }

    /* Prevent body scroll but maintain position */
    body.tooltip-open {
        position: fixed;
        width: 100%;
        overflow: hidden;
    }

    /* Remove hover effects for touch devices */
    .info-icon-container:hover .criteria-box {
        transform: none;
    }

    .criteria-box::before {
        display: none;
    }

    /* Increase touch targets */
    .info-icon {
        padding: 8px;
        margin: -8px;
    }
}

/* Smaller mobile screens */
@media (max-width: 480px) {
    .criteria-box {
        width: 95% !important;
        padding: 20px;
    }
}

/* Handle landscape orientation */
@media (max-width: 768px) and (orientation: landscape) {
    .criteria-box {
        top: 0 !important;
        transform: none !important;
        height: 100vh;
        border-radius: 0;
    }

    .criteria-box.show {
        transform: none !important;
    }
}

/* Style for links within the tooltip */
.criteria-box a {
    color: #fff;
    text-decoration: underline;
    transition: opacity 0.2s ease;
    position: relative;
    z-index: 2;
    padding: 2px 0; /* Add some padding to make links easier to click */
    display: inline-block; /* Allow padding to take effect */
}

.criteria-box a:hover {
    opacity: 0.8;
    text-decoration: none;
}

/* Add padding around the tooltip content for better readability */
.criteria-box p {
    margin: 0;
    line-height: 1.6; /* Slightly increased line height */
    position: relative;
    z-index: 2;
}

.instruction-blurb {
    margin: 20px 0;
    padding: 16px;
    border-radius: 8px;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
}

.instruction-content {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.instruction-content .info-icon {
    color: #0d6efd;
    font-size: 1.2em;
    margin-top: 3px;
}

.instruction-content p {
    margin: 0;
    color: #495057;
    line-height: 1.5;
    font-size: 1rem;
}

/* Prevent text selection in the tooltip */
.criteria-box {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* But allow text selection within the content */
.criteria-box p, 
.criteria-box a {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

/* Desktop Tooltip Styles */
@media (hover: hover) {
    .info-icon-container {
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-left: 8px;
    }

    .info-icon {
        color: var(--highlight-color);
        cursor: pointer;
        font-size: 18px;
        transition: color 0.3s ease;
        z-index: 2;
    }

    .info-icon:hover {
        color: #8B1811;
    }

    .criteria-box {
        position: absolute;
        top: calc(100% + 15px);
        left: 50%;
        transform: translateX(-50%) scale(0.95);
        background-color: black;
        color: white;
        padding: 15px 20px;
        border-radius: 8px;
        font-size: 15px;
        width: 350px;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        pointer-events: none;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .criteria-box::before {
        content: '';
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 12px;
        height: 12px;
        background-color: black;
    }

    /* Direction-aware positioning */
    .info-icon-container.right-aligned .criteria-box {
        left: auto;
        right: 0;
        transform: translateX(0) scale(0.95);
    }

    .info-icon-container.left-aligned .criteria-box {
        left: 0;
        right: auto;
        transform: translateX(0) scale(0.95);
    }

    .info-icon-container:hover .criteria-box {
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) scale(1);
        pointer-events: auto;
    }

    .info-icon-container.right-aligned:hover .criteria-box {
        transform: translateX(0) scale(1);
    }

    .info-icon-container.left-aligned:hover .criteria-box {
        transform: translateX(0) scale(1);
    }
}

/* Mobile Modal Styles */
@media (max-width: 768px) {
    .mobile-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2000;
        display: none;
    }

    .mobile-modal.active {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2000;
    }

    .mobile-modal-content {
        position: relative;
        width: 90%;
        max-width: 400px;
        background: white;
        padding: 25px;
        border-radius: 12px;
        z-index: 2001;
        color: #333;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        margin: 20px;
    }

    .mobile-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        color: #666;
        font-size: 24px;
        cursor: pointer;
        padding: 0;
    }

    .mobile-modal-content a {
        color: var(--highlight-color);
        font-weight: 500;
    }

    .mobile-modal-content p {
        color: #333;
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 15px;
    }
}

/* Shared styles for links and content */
.criteria-box a,
.mobile-modal-content a {
    color: var(--highlight-color);
    text-decoration: underline;
    font-weight: 500;
}

.criteria-box p,
.mobile-modal-content p {
    margin: 0;
    line-height: 1.6;
    font-size: 15px;
}

@media (max-width: 768px) {
    .mobile-modal-content p {
        font-size: 16px;
    }
}

.mobile-tooltip-content {
    padding: 10px 0;
}

.mobile-tooltip-content a {
    color: var(--highlight-color);
    text-decoration: underline;
    padding: 8px 0;
    margin: 4px 0;
    display: inline-block;
    font-weight: 500;
    position: relative;
    z-index: 2;
}

.mobile-tooltip-content a:hover,
.mobile-tooltip-content a:active {
    opacity: 0.8;
    text-decoration: none;
}

.mobile-tooltip-content p {
    margin: 0 0 15px 0;
    line-height: 1.6;
    font-size: 16px;
    color: #333;
}

/* Ensure proper spacing for the last paragraph */
.mobile-tooltip-content p:last-child {
    margin-bottom: 0;
}
